import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import medicareLogo from "./medicareLogo.png";
import medixbioLogo from "./medixbioLogo.jpg";
import synlabLogo from "./synlab_logo.png";

const ReportHeaderPrint = ({ handleDownload }) => {
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	let logo;

	switch (user.lab.id) {
		case 2:
			logo = medicareLogo;
			break;
		case 3:
			logo = synlabLogo;
			break;
		default:
			logo = medixbioLogo;
	}
	return (
		<div className="row" style={{ display: "flex", alignItems: "center" }}>
			<div className="col-lg-3">
				<a
					onClick={() => navigate("/")}
					className="text-nowrap logo-img cursor-pointer"
				>
					<img
						src={logo}
						className="logo"
						id="logo"
						style={{
							width: "100%",
							marginTop: "-25px",
							padding: "15px",
							maxWidth: "200px",
						}}
						alt=""
					/>
				</a>
			</div>
			<div className="col-lg-6 card-body p-4">
				<h6>Version 1.1</h6>
				<h2>Antimicrobial Treatment Map</h2>
			</div>
			<div className="col-lg-2 card-body p-4" style={{ marginRight: "40px" }}>
				<button
					className="btn btn-primary"
					style={{ width: "150px" }}
					onClick={handleDownload}
				>
					Save Report
				</button>
			</div>
		</div>
	);
};

export default ReportHeaderPrint;
