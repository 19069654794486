import { useSelector } from "react-redux";

const Report2 = () => {
	const { reportData, treatmentData } = useSelector((state) => state.treatment);

	// If treatmentData or reportData is missing, show an appropriate message.
	if (
		!treatmentData ||
		Object.keys(treatmentData.treatments || {}).length === 0
	) {
		return <p>No treatment data available.</p>;
	}

	return (
		<div>
			<h3>Treatment Report</h3>

			{/* Render details of careProvider, specimenID, etc. */}
			<p>Care Provider: {reportData.careProvider}</p>
			<p>Laboratory: {reportData.laboratory}</p>
			<p>Telephone: {reportData.telephone}</p>
			<p>Specimen ID: {reportData.specimenID}</p>
			<p>Drug Allergies: {reportData.drugAllergies}</p>

			{/* Render primary treatments for all pathogens and resistances */}
			<h4>Primary Treatments:</h4>
			{Object.keys(treatmentData.treatments).map((pathogenName) => {
				const pathogenData = treatmentData.treatments[pathogenName];

				// Ensure the data exists before accessing it
				if (!pathogenData) return null;

				return Object.keys(pathogenData.resistances).map((resistanceName) => {
					const resistanceData = pathogenData.resistances[resistanceName];

					// Ensure resistance data exists
					if (!resistanceData) return null;

					return (
						<div key={`${pathogenName}-${resistanceName}`}>
							<h5>
								{pathogenName} - Resistance: {resistanceName}
							</h5>
							<p>Load: {reportData.loadLevels?.[pathogenName] || "Unknown"}</p>
							<ul>
								{resistanceData.primaryTreatments.length > 0 ? (
									resistanceData.primaryTreatments.map((treatment) => (
										<li key={treatment}>
											{treatment}{" "}
											{pathogenData.overlap?.primary.includes(treatment) &&
												"(Overlap with Non-Primary)"}
										</li>
									))
								) : (
									<li>No Primary Treatments Available</li>
								)}
							</ul>
						</div>
					);
				});
			})}

			{/* Render non-primary treatments for all pathogens and resistances */}
			<h4>Non-Primary Treatments:</h4>
			{Object.keys(treatmentData.treatments).map((pathogenName) => {
				const pathogenData = treatmentData.treatments[pathogenName];

				// Ensure the data exists before accessing it
				if (!pathogenData) return null;

				return Object.keys(pathogenData.resistances).map((resistanceName) => {
					const resistanceData = pathogenData.resistances[resistanceName];

					// Ensure resistance data exists
					if (!resistanceData) return null;

					return (
						<div key={`${pathogenName}-${resistanceName}-nonPrimary`}>
							<h5>
								{pathogenName} - Resistance: {resistanceName}
							</h5>
							<p>Load: {reportData.loadLevels?.[pathogenName] || "Unknown"}</p>
							<ul>
								{resistanceData.nonPrimaryTreatments.length > 0 ? (
									resistanceData.nonPrimaryTreatments.map((treatment) => (
										<li key={treatment}>
											{treatment}{" "}
											{pathogenData.overlap?.nonPrimary.includes(treatment) &&
												"(Overlap with Primary)"}
										</li>
									))
								) : (
									<li>No Non-Primary Treatments Available</li>
								)}
							</ul>
						</div>
					);
				});
			})}

			{/* Render common treatments */}
			<h4>Common Treatments:</h4>
			<p>
				Common Primary:{" "}
				{treatmentData.commonPrimary?.allPathogens?.length > 0
					? treatmentData.commonPrimary.allPathogens.join(", ")
					: "No Common Primary Treatments"}
			</p>
			<p>
				Common Non-Primary:{" "}
				{treatmentData.commonNonPrimary?.allPathogens?.length > 0
					? treatmentData.commonNonPrimary.allPathogens.join(", ")
					: "No Common Non-Primary Treatments"}
			</p>
		</div>
	);
};

export default Report2;
