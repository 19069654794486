// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
}

thead th {
	background-color: #f0f0f0;
	font-weight: bold;
	padding: 10px;
}

tbody td {
	border: 1px solid #ddd;
	padding: 10px;
	text-align: center;
}

h4 {
	margin-top: 20px;
}

h5 {
	margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/report/Report3.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,yBAAyB;CACzB,mBAAmB;AACpB;;AAEA;CACC,yBAAyB;CACzB,iBAAiB;CACjB,aAAa;AACd;;AAEA;CACC,sBAAsB;CACtB,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,cAAc;AACf","sourcesContent":["table {\n\twidth: 100%;\n\tborder-collapse: collapse;\n\tmargin-bottom: 20px;\n}\n\nthead th {\n\tbackground-color: #f0f0f0;\n\tfont-weight: bold;\n\tpadding: 10px;\n}\n\ntbody td {\n\tborder: 1px solid #ddd;\n\tpadding: 10px;\n\ttext-align: center;\n}\n\nh4 {\n\tmargin-top: 20px;\n}\n\nh5 {\n\tmargin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
