import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/header/Header";
import ReportHeaderPrint from "../../components/reportHeaderPrint/ReportHeaderPrint";
import "./Report3.css"; // Optional for styling
import { PDFViewer } from "@react-pdf/renderer";
import {
	PDFDownloadLink,
	Document,
	Page,
	Text,
	Image,
	View,
	Font,
	pdf,
	StyleSheet,
} from "@react-pdf/renderer";
import medicareLogo from "./../../components/reportHeader/medicareLogo.png";
import medixbioLogo from "./../../components/reportHeader/medixbioLogo.jpg";
import synlabLogo from "./../../components/reportHeader/synlab_logo.png";
import { useNavigate } from "react-router-dom";

Font.register({
	family: "Roboto",
	src: "/fonts/Roboto-Regular.ttf",
});
Font.register({
	family: "Roboto",
	src: "/fonts/Roboto-Medium.ttf",
	fontWeight: "medium",
});

const Badge = ({ label }) => {
	let backgroundColor;

	switch (label) {
		case "High":
			backgroundColor = "#fa896b"; // High badge color
			break;
		case "Low":
			backgroundColor = "#FFAE1F"; // Low badge color
			break;
		default:
			backgroundColor = "#CCCCCC"; // Default color for unknown values
	}

	return (
		<View
			style={{
				backgroundColor: backgroundColor,
				borderRadius: 15,
				padding: 2,
				textAlign: "center",
				width: "40px",
				fontSize: "8px",
			}}
		>
			<Text style={{ color: "white", fontWeight: "600" }}>{label}</Text>
		</View>
	);
};

// Create Document Component
const MyDocument = ({ logo, reportData, treatmentData }) => {
	const findLeastTreatmentResistance = (resistances) => {
		return Object.keys(resistances).reduce((leastRes, currentRes) => {
			const leastOptionsCount = Math.min(
				resistances[leastRes].primaryTreatments.length,
				resistances[leastRes].nonPrimaryTreatments.length
			);
			const currentOptionsCount = Math.min(
				resistances[currentRes].primaryTreatments.length,
				resistances[currentRes].nonPrimaryTreatments.length
			);
			return currentOptionsCount < leastOptionsCount ? currentRes : leastRes;
		});
	};
	return (
		<Document>
			<Page
				size="A4"
				style={{
					backgroundColor: "#ffffff",
					padding: 15,
					fontFamily: "Roboto",
				}}
			>
				<View style={{ flexDirection: "row" }}>
					{/* Report Header */}
					<View
						style={{
							flex: 1,
							textAlign: "center",
							marginLeft: 25,
							marginTop: 25,
						}}
					>
						<Image src={logo} style={{ width: "100px", maxWidth: 200 }} />
					</View>
					<View style={{ flex: 3, padding: 16 }}>
						<Text style={{ fontSize: 12 }}>Version 1.1</Text>
						<Text
							style={{
								fontFamily: "Roboto",
								fontWeight: "medium",
								fontSize: 20,
							}}
						>
							Antimicrobial Treatment Map
						</Text>
					</View>
				</View>
				<View style={{ flexDirection: "row", marginLeft: 10 }}>
					<View style={{ flexDirection: "row", flex: 5 }}>
						{/* Column 1 */}
						<View style={{ flex: 1, padding: 16 }}>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Care provider:
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Lab
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Lab Contact:
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Telephone:
							</Text>
						</View>
						{/* Column 2 */}
						<View style={{ flex: 2, padding: 16 }}>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.careProvider}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.laboratory}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.labContact}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.telephone}
							</Text>
						</View>
						{/* Column 3 */}
						<View style={{ flex: 1, padding: 16 }}>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Specimen ID:
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Specimen Type:
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Drug allergies:
							</Text>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "medium",
									wordBreak: "break-word",
									padding: 4,
								}}
							>
								Report Date:
							</Text>
						</View>
						{/* Column 4 */}
						<View style={{ flex: 1, padding: 16 }}>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.specimenID}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.specimenType}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.drugAllergies}
							</Text>
							<Text
								style={{ fontSize: 9, wordBreak: "break-word", padding: 4 }}
							>
								{reportData?.reportDate}
							</Text>
						</View>
					</View>
				</View>
				<Text
					style={{
						fontSize: 12,
						wordBreak: "break-word",
						paddingTop: 15,
						paddingBottom: 10,
						marginLeft: 25,
						fontFamily: "Roboto",
						fontWeight: "medium",
					}}
				>
					RECOMMENDED FIRST LINE OF TREATMENT
				</Text>
				<View
					style={{
						flexDirection: "row",
						marginLeft: 15,
						marginRight: 5,
						borderTop: "1px solid #e2e2e2",
						borderBottom: "1px solid #e2e2e2",
					}}
				>
					{/* Column 1 */}
					<View style={{ flex: 2, padding: 10 }}>
						<Text
							style={{
								fontSize: 9,
								fontWeight: "medium",
								whiteSpace: "nowrap",
							}}
						>
							Pathogenic organisms
						</Text>
					</View>
					<View style={{ flex: 1, padding: 10 }}>
						<Text style={{ fontSize: 9, fontWeight: "medium" }}>
							Load (CFU/mL)
						</Text>
					</View>
					<View style={{ flex: 1, padding: 10 }}>
						<Text style={{ fontSize: 9, fontWeight: "medium" }}>
							Resistances
						</Text>
					</View>
					{/* Column 2 */}
					<View style={{ flex: 3, padding: 10 }}>
						<Text
							style={{
								fontSize: 9,
								fontWeight: "medium",
								wordBreak: "break-word",
							}}
						>
							Treatment
						</Text>
					</View>
				</View>

				{Object.keys(treatmentData.treatments).map((pathogenName) => {
					const pathogenData = treatmentData.treatments[pathogenName];

					if (!pathogenData) return null;

					// Find the resistance with the least number of treatment options
					const leastTreatmentResistance = findLeastTreatmentResistance(
						pathogenData.resistances
					);
					const resistanceData =
						pathogenData.resistances[leastTreatmentResistance];
					const loadLevel = reportData.loadLevels?.[pathogenName] || "Unknown";
					return (
						<View
							style={{
								flexDirection: "row",
								marginBottom: 10,
								marginLeft: 15,
								marginRight: 5,
							}}
						>
							{/* Column 1 */}
							<View style={{ flex: 2, padding: 10 }}>
								<Text style={{ fontSize: 9 }}>{pathogenName}</Text>
							</View>
							<View style={{ flex: 1, padding: 10 }}>
								<Badge label={loadLevel} />
							</View>
							<View style={{ flex: 1, padding: 10 }}>
								<Text style={{ fontSize: 9 }}>
									{Object.keys(pathogenData.resistances).map(
										(resistance, index) => {
											// Show "⚠️ N/A" only if "N/A" is the sole resistance
											if (
												resistance === "N/A" &&
												Object.keys(pathogenData.resistances).length === 1
											) {
												return <Text key={index}>N/A</Text>;
											}

											// Otherwise, just display the valid resistances
											if (resistance !== "N/A") {
												return (
													<Text key={index}>
														{resistance}
														{index <
															Object.keys(pathogenData.resistances).length -
																1 && ", "}
													</Text>
												);
											}

											return null; // Skip N/A if other resistances are present
										}
									)}
								</Text>
							</View>
							{/* Column 2 */}
							<View style={{ flex: 3, padding: 10 }}>
								<Text style={{ fontSize: 9, wordBreak: "break-word" }}>
									{resistanceData.primaryTreatments.length > 0
										? resistanceData.primaryTreatments.join(", ")
										: "No Primary Treatments"}
								</Text>
							</View>
						</View>
					);
				})}

				{treatmentData.commonPrimary?.allPathogens?.length > 0 &&
				reportData?.pathogens?.length > 1 ? (
					<View
						style={{
							flexDirection: "row",
							marginBottom: 10,
							marginLeft: 25,
							marginRight: 5,
							paddingTop: 10,
						}}
					>
						<Text style={{ fontWeight: "medium", fontSize: 12 }}>
							Common Recommended Treatments:{" "}
						</Text>
						<Text style={{ fontSize: 12 }}>
							{treatmentData.commonPrimary.allPathogens.length > 0
								? treatmentData.commonPrimary.allPathogens.join(", ")
								: "None"}
						</Text>
					</View>
				) : null}
				<Text
					style={{
						fontSize: 12,
						wordBreak: "break-word",
						paddingTop: 15,
						paddingBottom: 10,
						marginLeft: 25,
						marginTop: 15,
						fontFamily: "Roboto",
						fontWeight: "medium",
					}}
				>
					ALTERNATE LINE OF TREATMENT
				</Text>
				<View
					style={{
						flexDirection: "row",
						marginLeft: 15,
						marginRight: 5,
						borderTop: "1px solid #e2e2e2",
						borderBottom: "1px solid #e2e2e2",
					}}
				>
					{/* Column 1 */}
					<View style={{ flex: 2, padding: 10 }}>
						<Text
							style={{
								fontSize: 9,
								fontWeight: "medium",
								whiteSpace: "nowrap",
							}}
						>
							Pathogenic organisms
						</Text>
					</View>
					<View style={{ flex: 1, padding: 10 }}>
						<Text style={{ fontSize: 9, fontWeight: "medium" }}>
							Load (CFU/mL)
						</Text>
					</View>
					<View style={{ flex: 1, padding: 10 }}>
						<Text style={{ fontSize: 9, fontWeight: "medium" }}>
							Resistances
						</Text>
					</View>
					{/* Column 2 */}
					<View style={{ flex: 3, padding: 10 }}>
						<Text
							style={{
								fontSize: 9,
								fontWeight: "medium",
								wordBreak: "break-word",
							}}
						>
							Treatment
						</Text>
					</View>
				</View>

				{Object.keys(treatmentData.treatments).map((pathogenName) => {
					const pathogenData = treatmentData.treatments[pathogenName];

					if (!pathogenData) return null;

					// Find the resistance with the least number of treatment options for non-primary treatments
					const leastTreatmentResistance = findLeastTreatmentResistance(
						pathogenData.resistances
					);
					const resistanceData =
						pathogenData.resistances[leastTreatmentResistance];
					const loadLevel = reportData.loadLevels?.[pathogenName] || "Unknown";
					return (
						<View
							style={{
								flexDirection: "row",
								marginBottom: 10,
								marginLeft: 15,
								marginRight: 5,
							}}
						>
							{/* Column 1 */}
							<View style={{ flex: 2, padding: 10 }}>
								<Text style={{ fontSize: 9 }}>{pathogenName}</Text>
							</View>
							<View style={{ flex: 1, padding: 10 }}>
								<Badge label={loadLevel} />
							</View>
							<View style={{ flex: 1, padding: 10 }}>
								<Text style={{ fontSize: 9 }}>
									{Object.keys(pathogenData.resistances).map(
										(resistance, index) => {
											// Show "⚠️ N/A" only if "N/A" is the sole resistance
											if (
												resistance === "N/A" &&
												Object.keys(pathogenData.resistances).length === 1
											) {
												return <Text key={index}>N/A</Text>;
											}

											// Otherwise, just display the valid resistances
											if (resistance !== "N/A") {
												return (
													<Text key={index}>
														{resistance}
														{index <
															Object.keys(pathogenData.resistances).length -
																1 && ", "}
													</Text>
												);
											}

											return null; // Skip N/A if other resistances are present
										}
									)}
								</Text>
							</View>
							{/* Column 2 */}
							<View style={{ flex: 3, padding: 10 }}>
								<Text style={{ fontSize: 9, wordBreak: "break-word" }}>
									{resistanceData.nonPrimaryTreatments.length > 0
										? resistanceData.nonPrimaryTreatments.join(", ")
										: "No Primary Treatments"}
								</Text>
							</View>
						</View>
					);
				})}

				{treatmentData.commonNonPrimary?.allPathogens?.length > 0 &&
				reportData?.pathogens?.length > 1 ? (
					<View
						style={{
							flexDirection: "row",
							marginBottom: 10,
							marginLeft: 25,
							marginRight: 5,
							paddingTop: 10,
						}}
					>
						<Text style={{ fontWeight: "medium", fontSize: 12 }}>
							Common Alternate Treatments:{" "}
						</Text>
						<Text style={{ fontSize: 12 }}>
							{treatmentData.commonNonPrimary.allPathogens.length > 0
								? treatmentData.commonNonPrimary.allPathogens.join(", ")
								: "None"}
						</Text>
					</View>
				) : null}

				{Object.keys(treatmentData.treatments).map((pathogenName) => {
					const pathogenData = treatmentData.treatments[pathogenName];

					if (!pathogenData || !pathogenData.overlap) return null;

					return (
						<View
							style={{
								flexDirection: "row",
								marginBottom: 10,
								marginLeft: 25,
								marginRight: 5,
							}}
						>
							<Text style={{ fontSize: 9, fontWeight: "medium" }}>
								Overlapping Treatments (if any):
							</Text>
							<Text style={{ fontSize: 9 }}>{pathogenName}</Text>
							{pathogenData.overlap.primary.length > 0 && (
								<Text style={{ fontSize: 12 }}>
									Primary Overlaps: {pathogenData.overlap.primary.join(", ")}
								</Text>
							)}
							{pathogenData.overlap.nonPrimary.length > 0 && (
								<Text style={{ fontSize: 12 }}>
									Non-Primary Overlaps:{" "}
									{pathogenData.overlap.nonPrimary.join(", ")}
								</Text>
							)}
						</View>
					);
				})}
			</Page>
		</Document>
	);
};

const Report3 = () => {
	const navigate = useNavigate();
	const handleBeforePrint = () => {
		handleDownload();
	};

	useEffect(() => {
		window.addEventListener("beforeprint", handleBeforePrint);

		const handleKeyDown = (event) => {
			if ((event.ctrlKey || event.metaKey) && event.key === "p") {
				event.preventDefault(); // Prevent the default print dialog
				handleDownload(); // Call your print function
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("beforeprint", handleBeforePrint);
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, []);
	const handleDownload = async () => {
		let logo;

		switch (user.lab.id) {
			case 2:
				logo = medicareLogo;
				break;
			case 3:
				logo = synlabLogo;
				break;
			default:
				logo = medixbioLogo;
		}
		const doc = (
			<MyDocument
				logo={logo}
				reportData={reportData}
				treatmentData={treatmentData}
			/>
		);
		const blob = await pdf(doc).toBlob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		const date = new Date(reportData?.reportDate);
		const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1)
			.toString()
			.padStart(2, "0")}${date.getDate().toString().padStart(2, "0")}`;
		link.download = `Report-${reportData?.specimenID}-${formattedDate}.pdf`;
		link.click();
	};
	const { reportData, treatmentData } = useSelector((state) => state.treatment);
	const { user } = useSelector((state) => state.auth);
	// If treatmentData or reportData is missing, show an appropriate message.
	if (
		!treatmentData ||
		Object.keys(treatmentData.treatments || {}).length === 0
	) {
		return (
			<p>
				No treatment data available. <a onClick={() => navigate(-1)}>Go Back</a>
			</p>
		);
	}

	// Helper function to find the resistance with the fewest treatment options
	const findLeastTreatmentResistance = (resistances) => {
		return Object.keys(resistances).reduce((leastRes, currentRes) => {
			const leastOptionsCount = Math.min(
				resistances[leastRes].primaryTreatments.length,
				resistances[leastRes].nonPrimaryTreatments.length
			);
			const currentOptionsCount = Math.min(
				resistances[currentRes].primaryTreatments.length,
				resistances[currentRes].nonPrimaryTreatments.length
			);
			return currentOptionsCount < leastOptionsCount ? currentRes : leastRes;
		});
	};

	return (
		<>
			<div className="body-wrapper">
				<Header />
				<div className="container-fluid print-content">
					<ReportHeaderPrint handleDownload={handleDownload} />
					<div>
						<div className="row">
							<div className="col-md-3 d-flex align-items-strech"></div>
							<div
								className="col-md-4 col-sm-6 d-flex align-items-strech"
								style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
							>
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Care provider:
											</p>
										</div>
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.careProvider}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Lab:
											</p>
										</div>
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.laboratory}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Lab Contact:
											</p>
										</div>
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.labContact}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Telephone:
											</p>
										</div>
										<div className="col-md-6 col-sm-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.telephone}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div
								className="col-md-4  col-sm-6 d-flex align-items-strech"
								style={{ borderTop: "1px solid #e2e2e2", paddingTop: "25px" }}
							>
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Specimen ID:
											</p>
										</div>
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.specimenID}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Specimen Type:
											</p>
										</div>
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.specimenType}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Drug allergies:
											</p>
										</div>
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.drugAllergies}
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{ marginBottom: "4px", fontSize: "15px" }}
												className="form-label"
											>
												Report Date:
											</p>
										</div>
										<div className="col-md-6 d-flex align-items-strech">
											<p
												style={{
													marginBottom: "4px",
													fontWeight: "500",
													fontSize: "15px",
												}}
												className="form-label"
											>
												{reportData?.reportDate}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="row"
						style={{
							marginTop: "40px",
							paddingTop: "10px",
							borderTop: "1px solid #e2e2e2",
							fontWeight: "600",
							textTransform: "capitalize",
						}}
					>
						<h4 style={{ fontWeight: "600", paddingBottom: "20px" }}>
							RECOMMENDED FIRST LINE OF TREATMENT
						</h4>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Pathogenic organisms detected
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Load (CFU/mL)
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Resistances
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Treatment
							</h5>
						</div>
					</div>

					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData) return null;

						// Find the resistance with the least number of treatment options
						const leastTreatmentResistance = findLeastTreatmentResistance(
							pathogenData.resistances
						);
						const resistanceData =
							pathogenData.resistances[leastTreatmentResistance];

						return (
							<div
								className="row"
								style={{
									marginTop: "10px",
									paddingTop: "10px",
									borderTop: "1px solid #e2e2e2",
								}}
								key={pathogenName}
							>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{pathogenName}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{(() => {
											const loadLevel =
												reportData.loadLevels?.[pathogenName] || "Unknown";

											if (loadLevel === "High") {
												return (
													<span className="badge bg-danger rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											} else if (loadLevel === "Low") {
												return (
													<span className="badge bg-warning rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											} else {
												return (
													<span className="badge bg-info rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											}
										})()}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{Object.keys(pathogenData.resistances).map(
											(resistance, index) => {
												// Show "⚠️ N/A" only if "N/A" is the sole resistance
												if (
													resistance === "N/A" &&
													Object.keys(pathogenData.resistances).length === 1
												) {
													return (
														<span
															style={{ cursor: "default", color: "#000" }} // Pointer cursor for N/A
															data-bs-toggle="tooltip"
															title="No treatments available for the selected resistance, showing default treatments."
															key={index}
														>
															⚠️ N/A
														</span>
													);
												}

												// Otherwise, just display the valid resistances
												if (resistance !== "N/A") {
													return (
														<span key={index}>
															{resistance}
															{index <
																Object.keys(pathogenData.resistances).length -
																	1 && ", "}
														</span>
													);
												}

												return null; // Skip N/A if other resistances are present
											}
										)}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{resistanceData.primaryTreatments.length > 0 ? (
											resistanceData.primaryTreatments.join(", ")
										) : (
											<span className="text-muted">No Primary Treatments</span>
										)}
									</h3>
								</div>
							</div>
						);
					})}

					{treatmentData.commonPrimary?.allPathogens?.length > 0 &&
					reportData?.pathogens?.length > 1 ? (
						<div className="row" style={{ marginTop: "40px" }}>
							<h4 style={{ fontWeight: "bold" }}>
								Common Primary Treatments:{" "}
								<span style={{ fontWeight: "normal" }}>
									{treatmentData.commonPrimary.allPathogens.join(", ")}
								</span>
							</h4>
						</div>
					) : null}

					<div
						className="row"
						style={{
							marginTop: "90px",
							paddingTop: "10px",
							borderTop: "1px solid #e2e2e2",
							fontWeight: "600",
							textTransform: "capitalize",
						}}
					>
						<h4 style={{ fontWeight: "600", paddingBottom: "20px" }}>
							ALTERNATE LINE OF TREATMENT
						</h4>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Pathogenic organisms detected
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Load (CFU/mL)
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Resistances
							</h5>
						</div>
						<div className="col-md-3">
							<h5
								style={{
									fontWeight: "600",
									textTransform: "capitalize",
								}}
							>
								Treatment
							</h5>
						</div>
					</div>

					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData) return null;

						// Find the resistance with the least number of treatment options for non-primary treatments
						const leastTreatmentResistance = findLeastTreatmentResistance(
							pathogenData.resistances
						);
						const resistanceData =
							pathogenData.resistances[leastTreatmentResistance];

						return (
							<div
								className="row"
								style={{
									marginTop: "10px",
									paddingTop: "10px",
									borderTop: "1px solid #e2e2e2",
								}}
							>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{pathogenName}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{(() => {
											const loadLevel =
												reportData.loadLevels?.[pathogenName] || "Unknown";

											if (loadLevel === "High") {
												return (
													<span className="badge bg-danger rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											} else if (loadLevel === "Low") {
												return (
													<span className="badge bg-warning rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											} else {
												return (
													<span className="badge bg-info rounded-3 fw-semibold">
														{loadLevel}
													</span>
												);
											}
										})()}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{Object.keys(pathogenData.resistances).map(
											(resistance, index) => {
												// Show "⚠️ N/A" only if "N/A" is the sole resistance
												if (
													resistance === "N/A" &&
													Object.keys(pathogenData.resistances).length === 1
												) {
													return (
														<span
															style={{ cursor: "default", color: "#000" }} // Pointer cursor for N/A
															data-bs-toggle="tooltip"
															title="No treatments available for the selected resistance, showing default treatments."
															key={index}
														>
															⚠️ N/A
														</span>
													);
												}

												// Otherwise, just display the valid resistances
												if (resistance !== "N/A") {
													return (
														<span key={index}>
															{resistance}
															{index <
																Object.keys(pathogenData.resistances).length -
																	1 && ", "}
														</span>
													);
												}

												return null; // Skip N/A if other resistances are present
											}
										)}
									</h3>
								</div>
								<div className="col-md-3">
									<h3
										style={{
											fontSize: "16px",
										}}
									>
										{resistanceData.nonPrimaryTreatments.length > 0
											? resistanceData.nonPrimaryTreatments.join(", ")
											: "No Primary Treatments"}
									</h3>
								</div>
							</div>
						);
					})}
					{treatmentData.commonNonPrimary?.allPathogens?.length > 0 &&
					reportData?.pathogens?.length > 1 ? (
						<div className="row" style={{ marginTop: "40px" }}>
							<h4 style={{ fontWeight: "bold" }}>
								Common Alternate Treatments:{" "}
								<span style={{ fontWeight: "normal" }}>
									{treatmentData.commonNonPrimary.allPathogens.join(", ")}
								</span>
							</h4>
						</div>
					) : null}

					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData || !pathogenData.overlap) return null;

						return (
							<div key={`${pathogenName}-overlap`}>
								<h4>Overlapping Treatments (if any):</h4>
								<h5>{pathogenName}</h5>
								{pathogenData.overlap.primary.length > 0 && (
									<p>
										Primary Overlaps: {pathogenData.overlap.primary.join(", ")}
									</p>
								)}
								{pathogenData.overlap.nonPrimary.length > 0 && (
									<p>
										Non-Primary Overlaps:{" "}
										{pathogenData.overlap.nonPrimary.join(", ")}
									</p>
								)}
							</div>
						);
					})}
					{/* <PDFViewer>
						<MyDocument
							logo={user.lab.id === 2 ? medicareLogo : medixbioLogo}
							reportData={reportData}
							treatmentData={treatmentData}
						/>
					</PDFViewer> *
					<div>
						<PDFDownloadLink
							document={
								<MyDocument
									logo={user.lab.id === 2 ? medicareLogo : medixbioLogo}
									reportData={reportData}
									treatmentData={treatmentData}
								/>
							}
							fileName="myDocument.pdf"
							style={{
								textDecoration: "none",
								padding: "10px",
								margin: "10px",
								color: "white",
								backgroundColor: "blue",
								borderRadius: "5px",
							}}
						>
							{({ loading }) =>
								loading ? "Loading document..." : "Download PDF"
							}
						</PDFDownloadLink>
					</div>*/}
				</div>
				{/* Old Table Structure */}
				{/* <div> */}
				{/* Primary Treatment Table */}
				{/* <h4>Primary Treatments</h4>
					<table>
						<thead>
							<tr>
								<th>Pathogen</th>
								<th>Load Level</th>
								<th>Resistances</th>
								<th>Primary Treatment (Least Options)</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(treatmentData.treatments).map((pathogenName) => {
								const pathogenData = treatmentData.treatments[pathogenName];

								if (!pathogenData) return null;

								// Find the resistance with the least number of treatment options
								const leastTreatmentResistance = findLeastTreatmentResistance(
									pathogenData.resistances
								);
								const resistanceData =
									pathogenData.resistances[leastTreatmentResistance];

								return (
									<tr key={pathogenName}>
										<td>{pathogenName}</td>
										<td>
											{reportData.loadLevels?.[pathogenName] || "Unknown"}
										</td>
										<td>{Object.keys(pathogenData.resistances).join(", ")}</td>
										<td>
											{resistanceData.primaryTreatments.length > 0
												? resistanceData.primaryTreatments.join(", ")
												: "No Primary Treatments"}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table> */}

				{/* Render common primary treatments */}
				{/* <h4>Common Primary Treatments:</h4>
					<p>
						{treatmentData.commonPrimary?.allPathogens?.length > 0
							? treatmentData.commonPrimary.allPathogens.join(", ")
							: "No Common Primary Treatments"}
					</p> */}

				{/* Non-Primary Treatment Table */}
				{/* <h4>Non-Primary Treatments</h4>
					<table>
						<thead>
							<tr>
								<th>Pathogen</th>
								<th>Load Level</th>
								<th>Resistances</th>
								<th>Non-Primary Treatment (Least Options)</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(treatmentData.treatments).map((pathogenName) => {
								const pathogenData = treatmentData.treatments[pathogenName];

								if (!pathogenData) return null;

								// Find the resistance with the least number of treatment options for non-primary treatments
								const leastTreatmentResistance = findLeastTreatmentResistance(
									pathogenData.resistances
								);
								const resistanceData =
									pathogenData.resistances[leastTreatmentResistance];

								return (
									<tr key={pathogenName}>
										<td>{pathogenName}</td>
										<td>
											{reportData.loadLevels?.[pathogenName] || "Unknown"}
										</td>
										<td>{Object.keys(pathogenData.resistances).join(", ")}</td>
										<td>
											{resistanceData.nonPrimaryTreatments.length > 0
												? resistanceData.nonPrimaryTreatments.join(", ")
												: "No Non-Primary Treatments"}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table> */}

				{/* Render common non-primary treatments */}
				{/* <h4>Common Non-Primary Treatments:</h4>
					<p>
						{treatmentData.commonNonPrimary?.allPathogens?.length > 0
							? treatmentData.commonNonPrimary.allPathogens.join(", ")
							: "No Common Non-Primary Treatments"}
					</p> */}

				{/* Overlapping Treatments */}
				{/* <h4>Overlapping Treatments (if any):</h4>
					{Object.keys(treatmentData.treatments).map((pathogenName) => {
						const pathogenData = treatmentData.treatments[pathogenName];

						if (!pathogenData || !pathogenData.overlap) return null;

						return (
							<div key={`${pathogenName}-overlap`}>
								<h5>{pathogenName}</h5>
								{pathogenData.overlap.primary.length > 0 && (
									<p>
										Primary Overlaps: {pathogenData.overlap.primary.join(", ")}
									</p>
								)}
								{pathogenData.overlap.nonPrimary.length > 0 && (
									<p>
										Non-Primary Overlaps:{" "}
										{pathogenData.overlap.nonPrimary.join(", ")}
									</p>
								)}
							</div>
						);
					})} */}
				{/* </div> */}
				{/* Old Table Structure End */}
			</div>
		</>
	);
};

export default Report3;
